/* eslint-disable react/no-unescaped-entities */
import React from "react"

import Layout from "@components/layout"
import Seo from "@components/seo"
import PatientSupportProgramsComponent from "@components/patientSupportPrograms"

const PatientSupportProgramsAgnosticPage = ({ location }) => {
  return (
    <Layout
      location={location}
      headerType="agnostic"
      footerType="agnostic"
      exitModalColour="red"
      copyright={
        <>
          &copy; 2024 Jazz Pharmaceuticals, Inc.{" "}
          <nobr>US-XYW-2200416 Rev1124</nobr>
        </>
      }
    >
      <Seo
        title="myWAV™ - Your Digital 24/7 Resource | XYWAV®"
        description="Discover myWAV, a 24/7 digital tool for people taking XYWAV® (calcium, magnesium, potassium, and sodium oxybates) oral solution. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <PatientSupportProgramsComponent theme="agnostic" />
    </Layout>
  )
}

export default PatientSupportProgramsAgnosticPage
